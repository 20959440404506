import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layouts/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`This week, I decided to implement a feature from my backlog for the `}<a parentName="p" {...{
        "href": "https://mops.one/server"
      }}>{`Motoko Server`}</a>{` library. It is available now in version `}<inlineCode parentName="p">{`v1.0.0`}</inlineCode>{`.`}</p>
    <h2 {...{
      "id": "what-are-path-parameters"
    }}><a parentName="h2" {...{
        "href": "#what-are-path-parameters"
      }}>{`What are path parameters?`}</a></h2>
    <p>{`Path parameters are a convention used by Express and other server frameworks. They allow you to use a text string to represent not just the path that a certain handler should use, but also some information you want to gather about the request, informing your code how it should respond. This is an important feature for implementing `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/REST"
      }}>{`REST`}</a>{` patterns in your server.`}</p>
    <p>{`For example, if you were building an API that is meant to handle `}<inlineCode parentName="p">{`cats`}</inlineCode>{`, a GET request for `}<inlineCode parentName="p">{`/cats`}</inlineCode>{` would return all of the cats, or a page of cats if there were simply too many cats to return at once. `}</p>
    <p>{`To fetch a single cat, you might pass the ID of the cat as a secondary parameter. `}<inlineCode parentName="p">{`cats/1`}</inlineCode>{` would return a cat with an `}<inlineCode parentName="p">{`id`}</inlineCode>{` of `}<inlineCode parentName="p">{`1`}</inlineCode>{`, if that was how you wanted to keep track of them.`}</p>
    <p>{`Instead of writing `}</p>
    <pre {...{
      "className": "language-ts"
    }}><code parentName="pre" {...{
        "className": "language-ts"
      }}>{`server`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token method function property-access"
        }}>{`get`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token string"
        }}>{`"cats/1"`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span>{`
`}</code></pre>
    <p>{`For however many ids you have, you can now set up a handler with`}</p>
    <pre {...{
      "className": "language-ts"
    }}><code parentName="pre" {...{
        "className": "language-ts"
      }}>{`server`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token method function property-access"
        }}>{`get`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token string"
        }}>{`"cats/:id"`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{` `}<span parentName="code" {...{
          "className": "token function"
        }}>{`func`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span>{`request`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{` response`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
    `}<span parentName="code" {...{
          "className": "token comment"
        }}>{`// ...`}</span>{`
`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span>{`
`}</code></pre>
    <p>{`and the request will parse the ID from that position of the path. The library will return any number of path parameters to you as a `}<a parentName="p" {...{
        "href": "https://internetcomputer.org/docs/current/motoko/main/base/TrieMap/"
      }}>{`TrieMap`}</a>{` of key / value pairs that you can iterate through or check against.`}</p>
    <h2 {...{
      "id": "why-do-this"
    }}><a parentName="h2" {...{
        "href": "#why-do-this"
      }}>{`Why do this?`}</a></h2>
    <p>{`It's a pretty powerful feature! I'm working on a full rebuild of this blog using Zola, and I have a little gift list feature I built to keep track of ideas for birthdays or whatever instead of using Amazon or some proprietary tool. I wanted to allow people to "reserve" a specific gift, making coordination easier so people don't buy the same thing. I built a simple canister to handle this, and included the JavaScript agent in that page, and pointed it to the canister.`}</p>
    <p>{`However, in the refactor, I have zero JavaScript tooling and the site is static. It was a pain to import the agent (this gives me a new problem to solve at work, but that's beside the point), so I decided to upgrade the gift list canister to work over HTTP using `}<a parentName="p" {...{
        "href": "https://mops.one/server"
      }}>{`Motoko Server`}</a>{`.`}</p>
    <p>{`Adding in a path for all the gifts was simple, and I could have resorted to using query parameters which were already working but it felt inelegant! Instead I decided to set up a basic handler for `}<inlineCode parentName="p">{`/gift/:id`}</inlineCode>{` and added a `}<inlineCode parentName="p">{`/gift/:id/toggle`}</inlineCode>{` handler for `}<inlineCode parentName="p">{`POST`}</inlineCode>{` requests. Then I was able to handle the GiftItem as a simple stateful web component, written in JS without a bundler, preserving the existing functionality, but with far less overhead`}</p>
    <h2 {...{
      "id": "example-code"
    }}><a parentName="h2" {...{
        "href": "#example-code"
      }}>{`Example code`}</a></h2>
    <p>{`Here's how it looks in practice. First, here is the handler in the canister:`}</p>
    <pre {...{
      "className": "language-rust"
    }}><code parentName="pre" {...{
        "className": "language-rust"
      }}>{`server`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token function"
        }}>{`get`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span>{`
    `}<span parentName="code" {...{
          "className": "token string"
        }}>{`"/gifts/:id"`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{`
    `}<span parentName="code" {...{
          "className": "token function"
        }}>{`func`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span>{`req `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token class-name"
        }}>{`Request`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{` res `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token class-name"
        }}>{`ResponseClass`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`async`}</span>{` `}<span parentName="code" {...{
          "className": "token class-name"
        }}>{`Response`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
      ignore `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`do`}</span>{` `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`?`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`

        `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`let`}</span>{` id `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` req`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token macro property"
        }}>{`params!`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token function"
        }}>{`get`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token string"
        }}>{`"id"`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token operator"
        }}>{`!`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
        `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`let`}</span>{` gift `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token keyword"
        }}>{`await`}</span>{` `}<span parentName="code" {...{
          "className": "token function"
        }}>{`getGift`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span>{`id`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token operator"
        }}>{`!`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
        `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`let`}</span>{` expiry `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{` nanoseconds `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` `}<span parentName="code" {...{
          "className": "token class-name"
        }}>{`Int`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token function"
        }}>{`abs`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token class-name"
        }}>{`Time`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token function"
        }}>{`now`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span>{` `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`+`}</span>{` `}<span parentName="code" {...{
          "className": "token number"
        }}>{`100`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
        `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`return`}</span>{` res`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token function"
        }}>{`json`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
          status_code `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` `}<span parentName="code" {...{
          "className": "token number"
        }}>{`200`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
          body `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` `}<span parentName="code" {...{
          "className": "token function"
        }}>{`formatGift`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span>{`gift`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
          cache_strategy `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` #expireAfter expiry`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
        `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
      `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
      res`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token function"
        }}>{`json`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
        status_code `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` `}<span parentName="code" {...{
          "className": "token number"
        }}>{`404`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
        body `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` `}<span parentName="code" {...{
          "className": "token string"
        }}>{`"Gift not found"`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
        cache_strategy `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` #default`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
      `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
    `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{`
`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
`}</code></pre>
    <p>{`And then it can be fetched by the client simply by calling`}</p>
    <pre {...{
      "className": "language-js"
    }}><code parentName="pre" {...{
        "className": "language-js"
      }}><span parentName="code" {...{
          "className": "token keyword control-flow"
        }}>{`await`}</span>{` `}<span parentName="code" {...{
          "className": "token function"
        }}>{`fetch`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span>{`
    `}<span parentName="code" {...{
          "className": "token template-string"
        }}><span parentName="span" {...{
            "className": "token template-punctuation string"
          }}>{`\``}</span><span parentName="span" {...{
            "className": "token string"
          }}>{`https://`}</span><span parentName="span" {...{
            "className": "token interpolation"
          }}><span parentName="span" {...{
              "className": "token interpolation-punctuation punctuation"
            }}>{`\${`}</span>{`canisterId`}<span parentName="span" {...{
              "className": "token interpolation-punctuation punctuation"
            }}>{`}`}</span></span><span parentName="span" {...{
            "className": "token string"
          }}>{`.icp0.io/gifts/`}</span><span parentName="span" {...{
            "className": "token interpolation"
          }}><span parentName="span" {...{
              "className": "token interpolation-punctuation punctuation"
            }}>{`\${`}</span><span parentName="span" {...{
              "className": "token keyword"
            }}>{`this`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span><span parentName="span" {...{
              "className": "token property-access"
            }}>{`id`}</span><span parentName="span" {...{
              "className": "token interpolation-punctuation punctuation"
            }}>{`}`}</span></span><span parentName="span" {...{
            "className": "token template-punctuation string"
          }}>{`\``}</span></span>{`
`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token method function property-access"
        }}>{`then`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token keyword"
        }}>{`async`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token parameter"
        }}>{`response`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span>{` `}<span parentName="code" {...{
          "className": "token arrow operator"
        }}>{`=>`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
    `}<span parentName="code" {...{
          "className": "token keyword control-flow"
        }}>{`if`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span>{`response`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token property-access"
        }}>{`ok`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
        `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`const`}</span>{` json `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` `}<span parentName="code" {...{
          "className": "token keyword control-flow"
        }}>{`await`}</span>{` response`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token method function property-access"
        }}>{`json`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
        `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`const`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{` status `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span>{` `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` json`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
        `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`this`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token method function property-access"
        }}>{`updateCheckbox`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span>{`status`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`

        `}<span parentName="code" {...{
          "className": "token keyword control-flow"
        }}>{`return`}</span>{` response`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
    `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span>{`
    `}<span parentName="code" {...{
          "className": "token keyword control-flow"
        }}>{`throw`}</span>{` `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`new`}</span>{` `}<span parentName="code" {...{
          "className": "token class-name"
        }}>{`Error`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token string"
        }}>{`"Network response was not ok."`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
`}</code></pre>
    <p>{`Fully interacting with the canister as a traditional REST-ful backend, handling http calls with certified data`}</p>
    <p>{`Have fun with this feature!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      